<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<ion-title>{{ $route.meta.title }}</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true">
			<ion-list>
				<ion-item-sliding
					:key="index"
					v-for="(usuario, index) in users"
					@click="$router.push({name: `usuarios.edit`, params: {id: usuario.id}})"
				>
					<ion-item
						button
						lines="none"
					>
						<ion-icon name="person-outline" slot="start"></ion-icon>
						<ion-label>{{ usuario.name }}</ion-label>
						<ion-badge color="primary" v-if="usuario.status === 'active'">Ativo</ion-badge>
						<ion-badge color="danger" v-if="usuario.status === 'inactive'">Inativo</ion-badge>
					</ion-item>

					<ion-item-options side="end" v-if="$store.state.usuario.permissions['delete_users']">
						<ion-item-option
							color="danger"
							@click.prevent.stop="excluir(usuario.id)"
						>
							<ion-label>Excluir</ion-label>
						</ion-item-option>
					</ion-item-options>
				</ion-item-sliding>
			</ion-list>

			<ion-fab v-if="$store.state.usuario.permissions['create_users']" vertical="bottom" horizontal="end" slot="fixed">
				<ion-fab-button @click="$router.push({ name: `usuarios.create` })">
					<ion-icon name="add-outline"></ion-icon>
				</ion-fab-button>
			</ion-fab>
		</ion-content>
	</ion-page>
</template>

<script>
	//Ícones Ionic
	import {
		addOutline,
		personOutline
	} from "ionicons/icons";
	import { addIcons } from "ionicons";
	addIcons({
		"add-outline": addOutline,
		"person-outline": personOutline,
	});

	//Componentes Ionic
	import {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonList,
		IonItem,
		IonItemSliding,
		IonLabel,
		IonIcon,
		IonFabButton,
		IonFab,
		IonItemOption,
		IonItemOptions,
		IonBadge
	} from '@ionic/vue';

	//Firebase
	import { db } from "@/firebase";
	import {confirmAlert, toast} from "@/helpers";

	//Mixins
	import TitleMixin from "@/mixins/TitleMixin";

	export default {
		name: "UsuarioIndex",

		mixins: [
			TitleMixin
		],

		data() {
			return {
				carregandoUsuarios: false,
				users: []
			}
		},

		methods: {
			async excluir(id) {
				if (this.$store.state.usuario.permissions['delete_users']) {
					return confirmAlert(
						`Você tem certeza que deseja excluir o usuário <strong>${id}</strong>?`,
						() => {
							db.collection('users')
								.doc(id)
								.delete()
								.then(function() {
									toast("Usuário excluído com sucesso!");
								}).catch(function(error) {
								toast(`Erro ao tentar excluir usuário: ${error}`);
							});
						}
					);
				} else {
					toast('Você não tem permissão para esse recurso!');
				}
			}
		},

		created() {
			const vm = this;
			vm.carregandoUsuarios = true;
			this.$bind('users', db.collection('users').orderBy("name", "asc"))
				.then(function() {
					//
				})
				.catch(function (err) {
					toast(err);
				})
				.then(function () {
					vm.carregandoUsuarios = false;
				});
		},

		components: {
			IonButtons,
			IonContent,
			IonHeader,
			IonMenuButton,
			IonPage,
			IonTitle,
			IonToolbar,
			IonList,
			IonItem,
			IonItemSliding,
			IonLabel,
			IonIcon,
			IonFabButton,
			IonFab,
			IonItemOption,
			IonItemOptions,
			IonBadge
		}
	}
</script>
